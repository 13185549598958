import type { SectionWithProducts } from '../models/menu/section/section-with-products';
import { ProductMenu } from '../models/menu/product-menu';
import { CompanyConfiguration } from '../models/company/dto/company-configuration';
import { LocationConfiguration } from '../models/company/dto/location-configuration';
import { Product } from '../models/product/dto/product';
import { Menu } from '../models/menu/menu';
import { VariantTypeUtils } from './variant-type-utils';
import { SpotlightMenu } from '../models/menu/spotlight-menu';
import { SpotlightSectionRowViewModel } from '../modules/display/components/menus/spotlight-menu/building-blocks/menu-section/spotlight-product-section/spotlight-section-row-view-model';
import { SectionRowViewModelFeaturedCategory } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-featured-category';
import { CalyxTrichomesMenu } from '../models/menu/print/calyx-trichomes-menu';
import { SectionRowViewModelCalyxTrichomes } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-calyx-trichomes';
import { BandedMenu } from '../models/menu/print/banded-menu';
import { SectionRowViewModelBanded } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-banded';
import { BudSupplyMenu } from '../models/menu/print/bud-supply-menu';
import { SectionRowViewModelBudSupply } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-bud-supply';
import { PlantlifeMenu } from '../models/menu/print/plantlife-menu';
import { SectionRowViewModelPlantlife } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-plantlife';
import { PlantlifeNonSmokableMenu } from '../models/menu/print/plantlife-non-smokable-menu';
import { SectionRowViewModelPlantlifeNonSmokable } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-plantlife-non-smokable';
import { DoubleDutchMenu } from '../models/menu/print/double-dutch-menu';
import { SectionRowViewModelDoubleDutch } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-double-dutch';
import { BlendedMenu } from '../models/menu/print/blended-menu';
import { SectionRowViewModelBlended } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-blended';
import { ComeToBaskTrifoldMenu } from '../models/menu/print/come-to-bask-trifold-menu';
import { SectionRowViewModelComeToBask } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-come-to-bask';
import { DoubleGelatoMenu } from '../models/menu/print/double-gelato-menu';
import { LaCanapaMenu } from '../models/menu/product/la-canapa-menu';
import { SectionRowViewModelLaCanapa } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-la-canapa';
import { LaCanapaPrintMenu } from '../models/menu/print/la-canapa-print-menu';
import { LobsterButterMenu } from '../models/menu/product/lobster-butter-menu';
import { SectionRowViewModelLobsterButter } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-lobster-butter';
import { SoulBudMenu } from '../models/menu/product/soul-bud';
import { SectionRowViewModelSoulBud } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-soul-bud';
import { PurLifeMenu } from '../models/menu/product/pur-life-menu';
import { SectionRowViewModelPurLife } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-pur-life';
import { SoulBudPrintMenu } from '../models/menu/print/soul-bud-print-menu';
import { OGKushPrintMenu } from '../models/menu/print/og-kush-print-menu';
import { SectionRowViewModelOGKush } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-og-kush';
import { SpaceMonkeyPrintMenu } from '../models/menu/print/space-monkey-print-menu';
import { SectionRowViewModelSpaceMonkeyPrint } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-space-monkey-print';
import { FramedMenu } from '../models/menu/product/framed-menu';
import { SectionRowViewModelFramed } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-framed';
import { LuxLeafMenu } from '../models/menu/product/lux-leaf-menu';
import { SectionRowViewModelLuxLeaf } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-lux-leaf';
import { MarketingFeaturedCategoryMenu } from '../models/menu/marketing/featured-category/marketing-featured-category-menu';
import { MarketingFeaturedCategoryStaticGridMenu } from '../models/menu/marketing/featured-category/marketing-featured-category-static-grid-menu';
import { SectionRowViewModel } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { Variant } from '../models/product/dto/variant';
import { PrintCardMenu } from '../models/menu/print-card/print-card-menu';
import { CardData } from '../models/print-cards/card-data';
import { MenuStyle } from '../models/menu/dto/menu-style';
import { exists } from '../functions/exists';
import { PopsCannabisPrintCardMenu } from '../models/menu/print-card/pops-cannabis-print-card-menu';
import { PopsCannabisCardData } from '../models/print-cards/pops-cannabis-card-data';
import { CardStack } from '../models/menu/section/card-stacks/card-stack';
import { PlainJanePrintCardMenu } from '../models/menu/print-card/plain-jane-print-card-menu';
import { PlainJaneCardData } from '../models/print-cards/plain-jane-card-data';
import { DougPrintCardMenu } from '../models/menu/print-card/doug-print-card-menu';
import { DougCardData } from '../models/print-cards/doug-card-data';
import { SortVariantUtils } from './sort-variant-utils';
import { SortSectionRowViewModelUtils } from './sort-section-row-view-model-utils';
import { OrderReviewReportMenu } from '../models/menu/report/order-review-report-menu';
import { SectionRowViewModelOrderReviewReport } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-order-review-report';
import { MissJonesPrintCardMenu } from '../models/menu/print-card/miss-jones-print-card-menu';
import { MissJonesCardData } from '../models/print-cards/miss-jones-card-data';
import { AgentOrangeCardData } from '../models/print-cards/agent-orange-card-data';
import { AgentOrangePrintCardMenu } from '../models/menu/print-card/agent-orange-print-card-menu';
import { FikaPrintCardMenu } from '../models/menu/print-card/fika-print-card-menu';
import { FikaCardData } from '../models/print-cards/fika-card-data';
import { JaneDoeCardData } from '../models/print-cards/jane-doe-card-data';
import { JaneDoePrintCardMenu } from '../models/menu/print-card/jane-doe-print-card-menu';
import { TrueNorthPortraitPrintCardMenu } from '../models/menu/print-card/true-north-portrait-print-card-menu';
import { TrueNorthPortraitCardData } from '../models/print-cards/true-north-portrait-card-data';
import { TrueNorthLandscapePrintCardMenu } from '../models/menu/print-card/true-north-landscape-print-card-menu';
import { TrueNorthLandscapeCardData } from '../models/print-cards/true-north-landscape-card-data';
import { SessionsPrintCardMenu } from '../models/menu/print-card/sessions-print-card-menu';
import { SessionsCardData } from '../models/print-cards/sessions-card-data';
import { TrueNorthDarkMenu } from '../models/menu/product/true-north-dark-menu';
import { SectionRowViewModelTrueNorthDark } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-true-north-dark';
import { MenuUtils } from './menu-utils';
import { FikaBeveragePrintCardMenu } from '../models/menu/print-card/fika-beverage-print-card-menu';
import { FikaBeverageCardData } from '../models/print-cards/fika-beverage-card-data';
import { HoneyHazePrintCardMenu } from '../models/menu/print-card/honey-haze-print-card-menu';
import { HoneyHazeCardData } from '../models/print-cards/honey-haze-card';
import { FireAndFlowerPrintCardMenu } from '../models/menu/print-card/fire-and-flower-print-card-menu';
import { FireAndFlowerRegularPriceCardData } from '../models/print-cards/fire-and-flower/fire-and-flower-regular-price-card-data';
import { FireAndFlowerMemberPriceCardData } from '../models/print-cards/fire-and-flower/fire-and-flower-member-price-card-data';
import { FireAndFlowerCardData } from '../models/print-cards/fire-and-flower/fire-and-flower-card-data';
import { FireAndFlowerSalePriceCardData } from '../models/print-cards/fire-and-flower/fire-and-flower-sale-price-card-data';
import { PlantlifePrintCardMenu } from '../models/menu/print-card/plantlife-print-card-menu';
import { PlantlifeCardData } from '../models/print-cards/plantlife-card-data';
import { FireAndFlowerOldStylePrintCardMenu } from '../models/menu/print-card/fire-and-flower-old-style-print-card-menu';
import { FireAndFlowerOldStyleCardData } from '../models/print-cards/fire-and-flower/fire-and-flower-old-style-card-data';
import { PrintLabelMenu } from '../models/menu/print-label/print-label-menu';
import { BaseInventoryPrintLabelMenu } from '../models/menu/print-label/base-inventory-print-label-menu';
import { LabelData } from '../models/print-labels/label-data';
import { BaseInventoryLabelData } from '../models/print-labels/base-inventory-label-data';
import { BellaLunaPrintMenu } from '../models/menu/print/bella-luna-print-menu';
import { SectionRowViewModelBellaLuna } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/section-row-view-model-bella-luna';
import { NycdPrintCardMenu } from '../models/menu/print-card/nycd-print-card-menu';
import { NycdCardData } from '../models/print-cards/nycd-card-data';
import { StiiizyPrintCardMenu } from '../models/menu/print-card/stiiizy-print-card-menu';
import { StiiizyCardData } from '../models/print-cards/stiiizy-card-data';
import { GanjikaBeverageCardData } from '../models/print-cards/ganjika-beverage-card-data';

// @dynamic
export class SectionRowViewModelUtils {

  static generateRowViewModels(
    s: SectionWithProducts,
    menu: ProductMenu,
    compConfig: CompanyConfiguration,
    locConfig: LocationConfiguration,
    enabledProducts: Product[],
    outOfStockVarIds: string[],
    forcedVarPool?: string[],
    metadata?: any
  ): SectionRowViewModel[] {
    const rowVMs: SectionRowViewModel[] = [];
    if (s?.isInLineMode() || s?.isPricingTierGridMode()) {
      const builder = SectionRowViewModelUtils.getRowVMsForListMode;
      const vms = builder(s, menu, compConfig, locConfig, enabledProducts, outOfStockVarIds, forcedVarPool, metadata);
      rowVMs.push(...vms);
    } else if (s?.isGridMode() || s?.isChildVariantListMode()) {
      const builder = SectionRowViewModelUtils.getRowVMsWhereVariantsCanBeGroupedTogether;
      const vms = builder(s, menu, compConfig, locConfig, enabledProducts, outOfStockVarIds, forcedVarPool);
      rowVMs.push(...vms);
    }
    SectionRowViewModelUtils.checkForEmptySection(s, rowVMs);
    SectionRowViewModelUtils.applyStylesToRowVMs(s, menu, rowVMs);
    return SectionRowViewModelUtils.sortRowVMs(s, rowVMs);
  }

  private static checkForEmptySection(s: SectionWithProducts, rowVMs: SectionRowViewModel[]) {
    if (exists(s)) {
      s.empty = (rowVMs?.length ?? 0) < 1;
    }
  }

  private static applyStylesToRowVMs(
    s: SectionWithProducts,
    menu: Menu,
    rowVMs: SectionRowViewModel[]
  ) {
    // apply row styles
    rowVMs?.forEach(rvm => {
      const styles = menu?.getStyles();
      const productId = rvm?.product?.id;
      const variantId = rvm?.rowVariants?.firstOrNull()?.id;
      const sectionId = s?.id;
      if (rvm?.variantLineItemMode) {
        const productStyle = MenuStyle.findProductStyle(styles, productId, sectionId);
        const variantStyle = MenuStyle.findVariantStyle(styles, variantId, sectionId);
        if (productStyle) rvm.style = productStyle;
        // Variant style overrides product style
        if (variantStyle) rvm.style = variantStyle;
      } else {
        // grid mode
        rvm.style = MenuStyle.findProductStyle(styles, rvm?.product?.id, s?.id);
        // if only one variant exists, set variant style for row
        if (!rvm?.style && (rvm?.rowVariants?.length ?? 0) === 1) {
          rvm.style = MenuStyle.findVariantStyle(styles, variantId, sectionId);
        }
      }
    });
  }

  private static sortRowVMs(
    s: SectionWithProducts,
    rowVMs: SectionRowViewModel[]
  ): SectionRowViewModel[] {
    return rowVMs?.sort(SortSectionRowViewModelUtils.sortSectionRowViewModelsInSection(s));
  }

  static getRowVMsForListMode(
    section: SectionWithProducts,
    menu: ProductMenu,
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration,
    enabledProducts: Product[],
    outOfStockVariantIds: string[],
    forcedVariantPool?: string[],
    metadata?: any
  ): SectionRowViewModel[] {
    const rowVMs: SectionRowViewModel[] = [];
    for (const product of enabledProducts) {
      const variantIsVisible = (v: Variant) => {
        const variantIdPool = forcedVariantPool?.length > 0 ? forcedVariantPool : section?.enabledVariantIds;
        return variantIdPool?.includes(v?.id);
      };
      const variants = product?.variants?.filter(variantIsVisible) || [];
      for (const variant of variants) {
        const row = SectionRowViewModelUtils.getTypedSectionRowViewModel(menu);
        row.menu = menu;
        row.section = section;
        row.product = product;
        row.rowVariants = [variant];
        row.configTheme = menu?.theme;
        row.hideLabel = menu?.menuOptions?.hideLabel || false;
        row.variantLineItemMode = true;
        row.companyConfig = companyConfig;
        row.locationConfig = locationConfig;
        row.variantBadgeMap = section?.variantBadgeMap;
        // only show row if product is not out of stock or show zero stock is enabled
        const outOfStock = outOfStockVariantIds?.includes(variant?.id);
        if (MenuUtils.isPrintCardMenu(menu)) {
          if (row instanceof FireAndFlowerCardData && !(row instanceof FireAndFlowerOldStyleCardData)) {
            rowVMs.push(...SectionRowViewModelUtils.getFireAndFlowerRowViewModelsForItem(row, metadata));
          } else {
            // always show out of stock products in print cards, and never hide their prices
            rowVMs.push(row);
          }
        } else if (outOfStock && section?.showZeroStockItems) {
          row.hidePriceOnVariantIds.push(variant?.id);
          rowVMs.push(row);
        } else if (!outOfStock) {
          rowVMs.push(row);
        }
      }
    }
    return rowVMs;
  }

  static getRowVMsWhereVariantsCanBeGroupedTogether(
    sec: SectionWithProducts,
    menu: ProductMenu,
    cc: CompanyConfiguration,
    lc: LocationConfiguration,
    enabledProducts: Product[],
    outOfStockIds: string[],
    forcedVarPool?: string[]
  ): SectionRowViewModel[] {
    const builtRowVMs: SectionRowViewModel[] = [];
    for (const product of enabledProducts) {
      const variantType = [...new Set(product?.variants?.map(y => y.variantType))]?.firstOrNull();
      const variantIdPool = forcedVarPool?.length > 0 ? forcedVarPool : sec?.enabledVariantIds;
      const rowVariants = product?.variants?.filter(v => variantIdPool?.includes(v?.id)) || [];
      if (sec?.isChildVariantListMode() && !rowVariants?.length) {
        // child variant list mode doesn't need to show empty products like grid mode does, so skip this product
        continue; // this skips the current iteration of the for loop
      }
      const groupingNames = Array.from(new Set<string>(rowVariants?.map(v => v?.getFormattedUnitSize())));
      const builder = SectionRowViewModelUtils.getRowVMsFromVariantGrouping;
      if (VariantTypeUtils.isCapsuleType(variantType)) {
        // group each capsule by strength, and have package size as column header
        groupingNames?.forEach(groupName => {
          const groupVars = rowVariants.filter(v => v.getFormattedUnitSize() === groupName);
          const rowVMs = builder(sec, menu, cc, lc, outOfStockIds, groupVars, product, groupingNames, forcedVarPool);
          rowVMs?.forEach(vm => builtRowVMs.push(vm));
        });
      } else {
        const rowVMs = builder(sec, menu, cc, lc, outOfStockIds, rowVariants, product, groupingNames, forcedVarPool);
        rowVMs?.forEach(vm => {
          vm.variantBadgeMap = sec.variantBadgeMap;
          builtRowVMs.push(vm);
        });
      }
    }
    return builtRowVMs;
  }

  /**
   * Fire and flower can have 1 to 3 cards for each variant.
   * Regular price, member price, and sale price.
   */
  private static getFireAndFlowerRowViewModelsForItem(
    rowViewModel: FireAndFlowerRegularPriceCardData,
    metadata?: any
  ): SectionRowViewModel[] {
    const variant = rowViewModel.rowVariants?.firstOrNull();
    const tId = rowViewModel?.menu?.id;
    const locId = rowViewModel?.locationConfig?.locationId;
    const compId = rowViewModel?.companyConfig?.companyId;
    const secondaryLocationPrice = variant
      ?.locationPricing
      ?.find(it => it?.locationId === locId)
      ?.secondaryPrice || null;
    const secondaryCompanyPrice = variant
      ?.locationPricing
      ?.find(it => it?.locationId === compId)
      ?.secondaryPrice || null;
    const hasMemberPricing = Number.isFinite(secondaryLocationPrice || secondaryCompanyPrice);
    const priceStream = rowViewModel?.locationConfig?.priceFormat;
    const hideSale = rowViewModel?.getHideSale();
    if (exists(metadata)) {
      switch (metadata) {
        case 'member': {
          return hasMemberPricing
            ? [FireAndFlowerMemberPriceCardData.copyFrom(rowViewModel)]
            : [rowViewModel];
        }
        case 'sale': {
          return (!hideSale && rowViewModel?.rowVariants?.firstOrNull()?.hasDiscount(tId, locId, compId, priceStream))
            ? [FireAndFlowerSalePriceCardData.copyFrom(rowViewModel)]
            : [rowViewModel];
        }
        default: {
          return [rowViewModel];
        }
      }
    }
    const rowVMs: FireAndFlowerCardData[] = [rowViewModel];
    if (hasMemberPricing) {
      rowVMs.push(FireAndFlowerMemberPriceCardData.copyFrom(rowViewModel));
    }
    if (!hideSale && rowViewModel?.rowVariants?.firstOrNull()?.hasDiscount(tId, locId, compId, priceStream)) {
      rowVMs.push(FireAndFlowerSalePriceCardData.copyFrom(rowViewModel));
    }
    return rowVMs;
  }

  /**
   * Order matters when checking for PrintLabelMenu, because it is a subclass of PrintCardMenu.
   * If you check for PrintCardMenuFirst, then PrintLabelMenu will never be reached.
   */
  private static getTypedSectionRowViewModel(menu: Menu): SectionRowViewModel {
    if (menu instanceof PrintLabelMenu) {
      return SectionRowViewModelUtils.getPrintLabelMenuSectionRowViewModel(menu);
    } else if (menu instanceof PrintCardMenu) {
      return SectionRowViewModelUtils.getPrintCardMenuSectionRowViewModel(menu);
    } else {
      return SectionRowViewModelUtils.getMenuSectionRowViewModel(menu);
    }
  }

  private static getPrintLabelMenuSectionRowViewModel(menu: PrintLabelMenu): LabelData {
    switch (true) {
      case menu instanceof BaseInventoryPrintLabelMenu:
        return new BaseInventoryLabelData();
      default:
        return new LabelData();
    }
  }

  private static getPrintCardMenuSectionRowViewModel(menu: PrintCardMenu): CardData {
    switch (true) {
      case menu instanceof AgentOrangePrintCardMenu:
        return new AgentOrangeCardData();
      case menu instanceof DougPrintCardMenu:
        return new DougCardData();
      case menu instanceof FikaPrintCardMenu:
        return new FikaCardData();
      case menu instanceof FikaBeveragePrintCardMenu:
        return new FikaBeverageCardData();
      case menu instanceof FireAndFlowerPrintCardMenu:
        return new FireAndFlowerRegularPriceCardData();
      case menu instanceof FireAndFlowerOldStylePrintCardMenu:
        return new FireAndFlowerOldStyleCardData();
      case menu instanceof GanjikaBeverageCardData:
        return new GanjikaBeverageCardData();
      case menu instanceof HoneyHazePrintCardMenu:
        return new HoneyHazeCardData();
      case menu instanceof JaneDoePrintCardMenu:
        return new JaneDoeCardData();
      case menu instanceof MissJonesPrintCardMenu:
        return new MissJonesCardData();
      case menu instanceof NycdPrintCardMenu:
        return new NycdCardData();
      case menu instanceof PlainJanePrintCardMenu:
        return new PlainJaneCardData();
      case menu instanceof PlantlifePrintCardMenu:
        return new PlantlifeCardData();
      case menu instanceof PopsCannabisPrintCardMenu:
        return new PopsCannabisCardData();
      case menu instanceof SessionsPrintCardMenu:
        return new SessionsCardData();
      case menu instanceof StiiizyPrintCardMenu:
        return new StiiizyCardData();
      case menu instanceof TrueNorthLandscapePrintCardMenu:
        return new TrueNorthLandscapeCardData();
      case menu instanceof TrueNorthPortraitPrintCardMenu:
        return new TrueNorthPortraitCardData();
      default:
        return new CardData();
    }
  }

  private static getMenuSectionRowViewModel(menu: Menu): SectionRowViewModel {
    switch (true) {
      case menu instanceof SpotlightMenu:
        return new SpotlightSectionRowViewModel();
      case SectionRowViewModelUtils.isFeaturedCategory(menu):
        return new SectionRowViewModelFeaturedCategory();
      case menu instanceof CalyxTrichomesMenu:
        return new SectionRowViewModelCalyxTrichomes();
      case menu instanceof BandedMenu:
        return new SectionRowViewModelBanded();
      case menu instanceof BudSupplyMenu:
        return new SectionRowViewModelBudSupply();
      case menu instanceof PlantlifeMenu:
        return new SectionRowViewModelPlantlife();
      case menu instanceof PlantlifeNonSmokableMenu:
        return new SectionRowViewModelPlantlifeNonSmokable();
      case menu instanceof DoubleDutchMenu:
        return new SectionRowViewModelDoubleDutch();
      case menu instanceof BlendedMenu:
        return new SectionRowViewModelBlended();
      case menu instanceof ComeToBaskTrifoldMenu:
        return new SectionRowViewModelComeToBask();
      case menu instanceof DoubleGelatoMenu:
        return new SectionRowViewModelComeToBask();
      case menu instanceof LaCanapaMenu:
        return new SectionRowViewModelLaCanapa();
      case menu instanceof LaCanapaPrintMenu:
        return new SectionRowViewModelLaCanapa();
      case menu instanceof LobsterButterMenu:
        return new SectionRowViewModelLobsterButter();
      case menu instanceof SoulBudMenu:
        return new SectionRowViewModelSoulBud();
      case menu instanceof PurLifeMenu:
        return new SectionRowViewModelPurLife();
      case menu instanceof SoulBudPrintMenu:
        return new SectionRowViewModelSoulBud();
      case menu instanceof OGKushPrintMenu:
        return new SectionRowViewModelOGKush();
      case menu instanceof SpaceMonkeyPrintMenu:
        return new SectionRowViewModelSpaceMonkeyPrint();
      case menu instanceof FramedMenu:
        return new SectionRowViewModelFramed();
      case menu instanceof LuxLeafMenu:
        return new SectionRowViewModelLuxLeaf();
      case menu instanceof OrderReviewReportMenu:
        return new SectionRowViewModelOrderReviewReport();
      case menu instanceof TrueNorthDarkMenu:
        return new SectionRowViewModelTrueNorthDark();
      case menu instanceof BellaLunaPrintMenu:
        return new SectionRowViewModelBellaLuna();
      default:
        return new SectionRowViewModel();
    }
  }

  private static isFeaturedCategory(menu: Menu): boolean {
    const isDynamicFeaturedCategory = menu instanceof MarketingFeaturedCategoryMenu;
    const isStaticFeaturedCategory = menu instanceof MarketingFeaturedCategoryStaticGridMenu;
    return isDynamicFeaturedCategory || isStaticFeaturedCategory;
  }

  private static initializeRowVM(
    section: SectionWithProducts,
    menu: ProductMenu,
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration,
    variantsInGroup: Variant[],
    printCardOverflowed: boolean,
    product: Product,
    rowVariantGroupingNames: string[]
  ): SectionRowViewModel {
    const row = SectionRowViewModelUtils.getTypedSectionRowViewModel(menu);
    row.menu = menu;
    row.section = section;
    row.product = product;
    row.rowVariants = variantsInGroup;
    if (row instanceof CardData) row.overflowed = printCardOverflowed;
    row.hideLabel = menu?.menuOptions?.hideLabel || false;
    row.companyConfig = companyConfig;
    row.locationConfig = locationConfig;
    row.configTheme = menu?.theme;
    row.variantLineItemMode = false;
    return row;
  }

  /**
   * This is used when a menu is in grid mode. It can return multiple row view models because of print cards.
   * Each print card supports n variants, so each row view model represents a print card, and overflowed variants
   * are added to the next row view model.
   *
   * returns a row view model(s) for a group of variants under the same product.
   */
  private static getRowVMsFromVariantGrouping(
    section: SectionWithProducts,
    menu: ProductMenu,
    compConfig: CompanyConfiguration,
    locConfig: LocationConfiguration,
    outOfStockVariantIds: string[],
    groupVariants: Variant[],
    product: Product,
    groupingNames: string[],
    forcedVariantPool?: string[]
  ): SectionRowViewModel[] {
    const init = SectionRowViewModelUtils.initializeRowVM;
    // variants to display based on user selected columns
    const columnNames = section?.getGridColumnNames();
    const variantIdPool = forcedVariantPool?.length > 0 ? forcedVariantPool : section?.enabledVariantIds;
    const variantsToDisplay = groupVariants?.filter(variant => {
      if (section?.isChildVariantListMode()) {
        return variantIdPool?.includes(variant?.id);
      } else if (!variantIdPool?.find(i => i === variant?.id)) {
        return false;
      } else {
        return variant?.getGridNames(section?.layoutType, menu?.locationId)?.intersection(columnNames)?.length > 0;
      }
    });
    // only show row if product variants are not all out of stock or show zero stock is enabled
    const outOfStockRowVariants = variantsToDisplay?.filter(variant => outOfStockVariantIds?.includes(variant.id));
    const allRowVariantsOutOfStock = (variantsToDisplay?.length ?? 0) === (outOfStockRowVariants?.length ?? 0);
    const createRowVM = (variants: Variant[], printCardOverflowed: boolean = false): SectionRowViewModel => {
      const row = init(section, menu, compConfig, locConfig, variants, printCardOverflowed, product, groupingNames);
      if (MenuUtils.isPrintCardMenu(menu)) {
        // always show out of stock products in print cards, and never hide their prices
        return row;
      } else if (!allRowVariantsOutOfStock || section?.showZeroStockItems) {
        row.hidePriceOnVariantIds.push(...(outOfStockRowVariants?.map(variant => variant?.id) || []));
        return row;
      }
      return null;
    };
    // check for grid mode specifically because isChildVariantListMode() lands in here, but we don't want to spread
    // the variants across multiple cards in that case.
    if (section instanceof CardStack && section?.isGridMode()) {
      const maxPerCard = menu?.hydratedTheme?.printConfig?.gridCountMap?.get(section?.cardSize) ?? 1;
      const spreadEvenly = SectionRowViewModelUtils.spreadLargePrintCardGridAcrossMultipleCards;
      const chunkedVariants = spreadEvenly(variantsToDisplay, maxPerCard);
      return chunkedVariants
        ?.map((variantGroup, index) => createRowVM(variantGroup, (index > 0) && (variantGroup?.length === 1)))
        ?.filterNulls();
    } else {
      return [createRowVM(variantsToDisplay)]?.filterNulls();
    }
  }

  /**
   * Recursive function that evenly distributes grid variants across multiple print cards based on maxVariantsPerCard.
   * It also groups sizes that are nearest to each other.
   * Example: maxOf3 per card | 1g, 2g, 5g, 10g | [1g, 2g], [5g, 10g]
   * Example: maxOf4 per card | 1g, 2g, 5g, 10g, 14g | [1g, 2g, 5g], [10g, 14g]
   * Example: maxOf5 per card | 1g, 2g, 5g, 10g, 14g, 28g | [1g, 2g, 5g], [10g, 14g, 28g]
   * Example: maxVariantsPerCard = 3
   *   4 variants -> [2, 2]
   *   5 variants -> [3, 2]
   *   6 variants -> [3, 3]
   *   7 variants -> [3, 2, 2]
   *   8 variants -> [3, 3, 2]
   *   9 variants -> [3, 3, 3]
   *   10 variants -> [3, 3, 2, 2]
   *   11 variants -> [3, 3, 3, 2]
   *   12 variants -> [3, 3, 3, 3]
   * Example: maxVariantsPerCard = 4
   *   5 variants -> [3, 2]
   *   6 variants -> [3, 3]
   *   7 variants -> [4, 3]
   *   8 variants -> [4, 4]
   *   9 variants -> [3, 3, 3]
   *   10 variants -> [4, 3, 3]
   *   11 variants -> [4, 4, 3]
   *   12 variants -> [4, 4, 4]
   *   13 variants -> [4, 3, 3, 3]
   *   14 variants -> [4, 4, 3, 3]
   *   15 variants -> [4, 4, 4, 3]
   *   16 variants -> [4, 4, 4, 4]
   * Example: maxVariantsPerCard = 5
   *   6 variants -> [3, 3]
   *   7 variants -> [4, 3]
   *   8 variants -> [4, 4]
   *   9 variants -> [5, 4]
   *   10 variants -> [5, 5]
   *   11 variants -> [4, 4, 3],
   *   12 variants -> [4, 4, 4],
   *   13 variants -> [5, 4, 4],
   *   14 variants -> [5, 5, 4],
   *   15 variants -> [5, 5, 5],
   *   16 variants -> [4, 4, 4, 4],
   *   17 variants -> [5, 4, 4, 4],
   *   18 variants -> [5, 5, 4, 4],
   *   19 variants -> [5, 5, 5, 4],
   *   20 variants -> [5, 5, 5, 5],
   */
  public static spreadLargePrintCardGridAcrossMultipleCards(
    variantsToDisplay: Variant[],
    maxVariantsPerCard: number,
  ): Variant[][] {
    variantsToDisplay?.sort(SortVariantUtils.sortVariantsByUnitSizeAscElsePackagedQuantityAsc);
    const chunkedVariants = variantsToDisplay?.chunkedList(maxVariantsPerCard);
    let tooSmall: Variant[][];
    /** Take from left neighbor if it has more variants than the current chunk */
    const takeFromLeftNeighbor = (chunks: Variant[][], chunk: Variant[]): void => {
      const chunkIndex = chunks?.indexOf(chunk);
      if (!chunkIndex || !chunk?.length) return;
      const leftNeighbor = chunks[chunkIndex - 1];
      if (leftNeighbor?.length > chunk?.length) {
        takeFromLeftNeighbor(chunks, leftNeighbor);
        const variant = leftNeighbor?.pop();
        chunk.unshift(variant);
      }
    };
    const fillTooSmallPile = () => {
      tooSmall = chunkedVariants?.filter(chunk => {
        const largestListSize = Math.max(...(chunkedVariants?.map(c => c?.length ?? 0) || []), 0);
        return (largestListSize - (chunk?.length ?? 0)) > 1;
      });
    };
    fillTooSmallPile();
    while (tooSmall?.length > 0) {
      takeFromLeftNeighbor(chunkedVariants, tooSmall?.last());
      fillTooSmallPile();
    }
    return chunkedVariants;
  }

}
