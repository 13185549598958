import { StrainType } from '../../../../../../../../../models/enum/dto/strain-classification.enum';
import { CannabisUnitOfMeasure } from '../../../../../../../../../models/enum/dto/cannabis-unit-of-measure.enum';
import { SectionItemOverflowStatus } from '../../../../../../../../../models/enum/shared/section-item-overflow-status.enum';
import { CannabinoidDisplayType } from '../../../../../../../../../models/enum/shared/cannabinoid-display-type.enum';
import { VariantType } from '../../../../../../../../../models/enum/dto/variant-type.enum';
import { ProductType } from '../../../../../../../../../models/enum/dto/product-type.enum';
import { SectionColumnConfigDataValue, SectionColumnConfigProductInfoKey } from '../../../../../../../../../models/menu/section/section-column-config';
import { SectionLayoutType } from '../../../../../../../../../models/enum/dto/section-layout-type.enum';
import { StringUtils } from '../../../../../../../../../utils/string-utils';
import { LabelComponentInterface } from '../../../../../../../../labels/components/label-component-interface';
import { ArrayUtils } from '../../../../../../../../../utils/array-utils';
import { StrainTypeUtils } from '../../../../../../../../../utils/strain-type-utils';
import { LocationPriceStream } from '../../../../../../../../../models/enum/shared/location-price-stream';
import { exists } from '../../../../../../../../../functions/exists';
import { VariantTypeUtils } from '../../../../../../../../../utils/variant-type-utils';
import { MenuUtils } from '../../../../../../../../../utils/menu-utils';
import type { CompanyConfiguration } from '../../../../../../../../../models/company/dto/company-configuration';
import type { LocationConfiguration } from '../../../../../../../../../models/company/dto/location-configuration';
import type { Menu } from '../../../../../../../../../models/menu/menu';
import type { MenuStyle } from '../../../../../../../../../models/menu/dto/menu-style';
import type { Product } from '../../../../../../../../../models/product/dto/product';
import type { ProductMenu } from '../../../../../../../../../models/menu/product-menu';
import type { SectionColumnViewModel } from '../section-column-view-models/SectionColumnViewModel';
import type { SectionWithProducts } from '../../../../../../../../../models/menu/section/section-with-products';
import type { Subheader } from '../../../../../../../../../models/menu/section/subheader';
import type { Variant } from '../../../../../../../../../models/product/dto/variant';
import type { VariantBadge } from '../../../../../../../../../models/product/dto/variant-badge';
import { BadgeUtils } from '../../../../../../../../../utils/badge-utils';
import { TerpeneUnitOfMeasure } from '../../../../../../../../../models/enum/dto/terpene-unit-of-measure';
import { TerpeneDisplayType } from '../../../../../../../../../models/enum/shared/terpene-display-type';

export class SectionRowViewModel implements LabelComponentInterface {

  menu: ProductMenu;
  section: SectionWithProducts;
  subHeader: Subheader;
  product: Product;
  rowVariants: Variant[];
  variantLineItemMode = false;
  collapseContent = false;
  hideLabel = false;
  configTheme: string;
  style: MenuStyle;
  hidePriceOnVariantIds: string[] = [];
  companyConfig: CompanyConfiguration;
  locationConfig: LocationConfiguration;
  variantBadgeMap: Map<string, VariantBadge[]> = new Map<string, VariantBadge[]>();
  itemOverflowStatus: SectionItemOverflowStatus = SectionItemOverflowStatus.NotSet;
  // used for section level snap transition
  private uniqueIdOffset = 0;

  getBrand(): string {
    return this.product?.getBrand(this.rowVariants?.map(v => v.id));
  }

  getManufacturer(): string {
    const manufacturers = this.rowVariants?.filter(v => !!v?.manufacturer).map(v => v?.manufacturer).filterNulls();
    return StringUtils.getStringMode(manufacturers);
  }

  getProductSubtitle(): string {
    return this.menu?.getProductSubtitle(this.section, this.product, this);
  }

  getProductTertiaryTitle(): string {
    return this.menu?.getProductTertiaryTitle(this.section, this.product, this);
  }

  getImageContainerFit(): string {
    const config = this.section?.columnConfig?.get(SectionColumnConfigProductInfoKey.Asset);
    return config?.dataValue ?? 'contain';
  }

  getImageOpacity(): number {
    const config = this.section?.columnConfig?.get(SectionColumnConfigProductInfoKey.Asset);
    const opacity = config?.columnOpacity;
    return opacity === 0 ? 1 : opacity;
  }

  getRowTitle(): string {
    return this.menu?.getProductTitle(
      this.section,
      this.product,
      this.rowVariants,
      this.locationConfig?.priceFormat
    );
  }

  getVariantIdsSeperatedBy(separator: string): string {
    return this.rowVariants?.map(v => v.id).join(separator);
  }

  /**
   * Use getReadableStrainType if you need to display the strain type in the UI.
   */
  getStrainType(): StrainType {
    return this.variantLineItemMode
      ? (this.rowVariants?.find(v => exists(v?.classification))?.classification ?? StrainType.UNKNOWN)
      : (this.product?.classification ?? StrainType.UNKNOWN);
  }

  getReadableStrainType(
    strainTypeMode: SectionColumnConfigDataValue = SectionColumnConfigDataValue.StrainTypeWord
  ): string {
    const strainType = this.getStrainType();
    if (strainTypeMode === SectionColumnConfigDataValue.StrainTypeSymbol) {
      return StrainTypeUtils.getSymbol(strainType);
    } else {
      return StrainTypeUtils.getStandardizedName(strainType, this.menu?.shouldStandardizeDominantStrainType());
    }
  }

  getStrainTypeColor(): string {
    switch (this.product?.classification) {
      case StrainType.Indica:
      case StrainType.IndicaDominant:
        return '#7640EF';
      case StrainType.Sativa:
      case StrainType.SativaDominant:
        return '#EF4040';
      case StrainType.Hybrid:
        return '#55C678';
      case StrainType.Blend:
        return '#6F6F6F';
      case StrainType.CBD:
        return '#4E6A9D';
      case StrainType.UNKNOWN:
        return '#222222';
      default:
        return '#222222';
    }
  }

  getMaxRowStock(): number {
    return Math.max(...this.getScopedVisibleVariantsElseRowVariants().map(v => v.inventory.quantityInStock));
  }

  getMinRowPrice(): number {
    const themeId = this.menu?.theme;
    const rowVariants = this.getScopedVisibleVariantsElseRowVariants() || [];
    const locationId = this.menu?.locationId;
    const companyId = this.menu?.companyId;
    const priceStream = this.locationConfig?.priceFormat;
    const hideSale = this.getHideSale();
    return Math.min(...rowVariants.map(v => v.getVisiblePrice(themeId, locationId, companyId, priceStream, hideSale)));
  }

  getMinRowSecondaryPrice(): number {
    const themeId = this.menu?.theme;
    const locId = this.menu?.locationId;
    const companyId = this.menu?.companyId;
    const priceStream = this.locationConfig?.priceFormat;
    const hideSale = this.getHideSale();
    const mode = this.section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.dataValue;
    const rowVariants = this.getScopedVisibleVariantsElseRowVariants() || [];
    switch (mode) {
      case SectionColumnConfigDataValue.PricePerUOM:
        return Math.min(...rowVariants.map(v => v.getPricePerUOM(themeId, locId, companyId, priceStream, hideSale)));
      case SectionColumnConfigDataValue.OriginalPrice:
        return Math.min(...rowVariants.map(v => v.getPriceWithoutDiscounts(themeId, locId, companyId, priceStream)));
      case SectionColumnConfigDataValue.SaleOriginalPrice:
        const saleOriginalPrices = rowVariants
          .map(v => v.getSaleOriginalPriceOrNull(themeId, locId, companyId, priceStream))
          .filterNulls();
        return saleOriginalPrices.length > 0 ? Math.min(...saleOriginalPrices) : null;
      case SectionColumnConfigDataValue.TaxesInPrice:
        return Math.min(...rowVariants.map(v => v.getTaxesInPrice(themeId, locId, companyId)));
      case SectionColumnConfigDataValue.TaxesInRoundedPrice:
        return Math.min(...rowVariants.map(v => v.getTaxesInRoundedPrice(themeId, locId, companyId)));
      case SectionColumnConfigDataValue.PreTaxPrice:
        return Math.min(...rowVariants.map(v => v.getPreTaxPrice(themeId, locId, companyId)));
      default:
        return Math.min(...rowVariants.map(v => v.getSecondaryPrice(themeId, companyId, locId, priceStream)));
    }
  }

  getMaxRowPrice(): number {
    const themeId = this.menu?.theme;
    const locationId = this.menu?.locationId;
    const companyId = this.menu?.companyId;
    const priceStream = this.locationConfig?.priceFormat;
    const hideSale = this.getHideSale();
    const rowVariants = this.getScopedVisibleVariantsElseRowVariants() || [];
    return Math.max(...rowVariants.map(v => v.getVisiblePrice(themeId, locationId, companyId, priceStream, hideSale)));
  }

  getMaxRowSecondaryPrice(): number {
    const themeId = this.menu?.theme;
    const locId = this.menu?.locationId;
    const companyId = this.menu?.companyId;
    const priceStream = this.locationConfig?.priceFormat;
    const hideSale = this.getHideSale();
    const mode = this.section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.dataValue;
    const rowVariants = this.getScopedVisibleVariantsElseRowVariants() || [];
    switch (mode) {
      case SectionColumnConfigDataValue.PricePerUOM:
        return Math.max(...rowVariants.map(v => v.getPricePerUOM(themeId, locId, companyId, priceStream, hideSale)));
      case SectionColumnConfigDataValue.OriginalPrice:
        return Math.max(...rowVariants.map(v => v.getPriceWithoutDiscounts(themeId, locId, companyId, priceStream)));
      case SectionColumnConfigDataValue.SaleOriginalPrice:
        const saleOriginalPrices = rowVariants
          .map(v => v.getSaleOriginalPriceOrNull(themeId, locId, companyId, priceStream))
          .filterNulls();
        return saleOriginalPrices.length > 0 ? Math.max(...saleOriginalPrices) : null;
      case SectionColumnConfigDataValue.TaxesInPrice:
        return Math.max(...rowVariants.map(v => v.getTaxesInPrice(themeId, locId, companyId)));
      case SectionColumnConfigDataValue.TaxesInRoundedPrice:
        return Math.max(...rowVariants.map(v => v.getTaxesInRoundedPrice(themeId, locId, companyId)));
      case SectionColumnConfigDataValue.PreTaxPrice:
        return Math.max(...rowVariants.map(v => v.getPreTaxPrice(themeId, locId, companyId)));
      default:
        return Math.max(...rowVariants.map(v => v.getSecondaryPrice(themeId, companyId, locId, priceStream)));
    }
  }

  protected getRowRangeCannabinoid(
    getNumericValue: (v: Variant) => number,
    getNumericMinValue: (v: Variant) => number,
    getNumericMaxValue: (v: Variant) => number,
    fixedPoint?: number
  ): string {
    let minVal: number;
    let maxVal: number;
    if (this.companyConfig?.cannabinoidDisplayType === CannabinoidDisplayType.Exact) {
      const variants = this.getScopedVisibleVariantsElseRowVariants();
      const [rangeVariants, exactVariants] = ArrayUtils.partition(variants, (v) => v.useCannabinoidRange);
      const exactValues = exactVariants.map(v => getNumericValue(v));
      const rangeMinValues = rangeVariants.map(v => getNumericMinValue(v));
      const rangeMaxValues = rangeVariants.map(v => getNumericMaxValue(v));
      minVal = Math.min(...exactValues, ...rangeMinValues);
      maxVal = Math.max(...exactValues, ...rangeMaxValues);
    } else {
      minVal = Math.min(...this.getScopedVisibleVariantsElseRowVariants().map(v => getNumericMinValue(v)));
      maxVal = Math.max(...this.getScopedVisibleVariantsElseRowVariants().map(v => getNumericMaxValue(v)));
    }
    return this.getFixedPointRangeStringNoUnits(minVal, maxVal, fixedPoint);
  }

  getMinRowCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string): number {
    return Math.min(
      ...this.getScopedVisibleVariantsElseRowVariants().map(v => {
        return v.useCannabinoidRange
          ? v.getNumericMinCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)
          : v.getNumericCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased);
      })
    );
  }

  getRowRangeCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string, fixedPoint?: number): string {
    return this.getRowRangeCannabinoid(
      (v) => v.getNumericCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased),
      (v) => v.getNumericMinCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased),
      (v) => v.getNumericMaxCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased),
      fixedPoint
    );
  }

  getMaxRowCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string): number {
    return Math.max(
      ...this.getScopedVisibleVariantsElseRowVariants().map(v => {
        return v.useCannabinoidRange
          ? v.getNumericMaxCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)
          : v.getNumericCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased);
      })
    );
  }

  getTopTerpene(): string {
    return this.getScopedVisibleVariantsElseRowVariants().map(v => v?.getVariantTopTerpene()).firstOrNull();
  }

  protected getFixedPointRangeStringNoUnits(minVal: number, maxVal: number, fixedPoint?: number): string {
    if (minVal !== -1 && maxVal !== -1) {
      if (minVal === maxVal) {
        if (minVal < 1) {
          return String('<1').trim();
        } else {
          // ie, don't return 14-14, just return 14,
          return fixedPoint ? minVal.toFixed(fixedPoint) : `${minVal}`;
        }
      }
      return fixedPoint
        ? (minVal.toFixed(fixedPoint) + ' - ' + maxVal.toFixed(fixedPoint))
        : `${minVal} - ${maxVal}`;
    }
    return '';
  }

  getMinRowNumericSize(): number {
    return Math.min(...this.getScopedVisibleVariantsElseRowVariants().map(v => v.getNumericSize()));
  }

  getMaxRowNumericSize(): number {
    return Math.max(...this.getScopedVisibleVariantsElseRowVariants().map(v => v.getNumericSize()));
  }

  public getCannabinoid(cannabinoid: string): string {
    const variants = this.getScopedVisibleVariantsElseRowVariants();
    if (variants.length > 0) {
      if (variants?.firstOrNull()?.shouldDisplayCannabinoidValue()) {
        if (variants?.firstOrNull().cannabisUnitOfMeasure === CannabisUnitOfMeasure.NA) {
          return '--';
        }
        const unitOfMeasureString = variants?.firstOrNull()?.getCannabinoidUnitOfMeasureString(this.menu);
        // Get cannabinoid value
        if (this.displayCannabinoidInRanges()) {
          return this.getCannabinoidRowRangeAsString(cannabinoid, unitOfMeasureString);
        } else {
          return this.getCannabinoidAsString(cannabinoid, unitOfMeasureString);
        }
      } else {
        return '-';
      }
    }
    return '';
  }

  protected getCannabinoidRowRangeAsString(
    cannabinoid: string,
    unitOfMeasureString: string,
    fixedPoint?: number
  ): string {
    const range = this.getRowRangeCannabinoidOrTerpene(cannabinoid, fixedPoint);
    if (range === '') {
      return `${CannabisUnitOfMeasure.NA}`;
    } else {
      return `${range} ${this.showCUOMInHeader() ? '' : unitOfMeasureString}`.trim();
    }
  }

  protected getCannabinoidAsString(cannabinoid: string, units: string, fixedPoint?: number): string {
    const parsedCannabinoid = this.getMinRowCannabinoidOrTerpene(cannabinoid);
    if (parsedCannabinoid < 1) {
      return (this.showCUOMInHeader() ? `<1` : `<1 ${units}`).trim();
    } else {
      if (fixedPoint) {
        return `${parsedCannabinoid.toFixed(fixedPoint)} ${this.showCUOMInHeader() ? '' : units}`.trim();
      } else {
        const roundedCannabinoid = `${(Math.round((parsedCannabinoid + Number.EPSILON) * 100) / 100)} `
          + `${this.showCUOMInHeader() ? '' : units}`;
        return roundedCannabinoid.trim();
      }
    }
  }

  getTerpene(terpenePascalCased: string): string {
    const variants = this.getScopedVisibleVariantsElseRowVariants();
    if (variants.length > 0) {
      if (variants?.firstOrNull()?.shouldDisplayTerpeneValue()) {
        if (variants?.firstOrNull().terpeneUnitOfMeasure === TerpeneUnitOfMeasure.NA) {
          return '--';
        }
        const unitOfMeasureString = variants?.firstOrNull()?.getTerpeneUnitOfMeasureString(this.menu);
        // Get terpene value
        if (this.displayTerpeneInRanges()) {
          return this.getTerpeneRowRangeAsString(terpenePascalCased, unitOfMeasureString);
        } else {
          return this.getTerpeneAsString(terpenePascalCased, unitOfMeasureString);
        }
      } else {
        return '-';
      }
    }
    return '';
  }

  protected getTerpeneRowRangeAsString(
    terpenePascalCased: string,
    unitOfMeasureString: string,
    fixedPoint?: number
  ): string {
    const terpeneCamelCased = StringUtils.pascalCaseToCamelCase(terpenePascalCased);
    const range = this.getRowRangeCannabinoidOrTerpene(terpeneCamelCased, fixedPoint);
    if (range === '') {
      return `${TerpeneUnitOfMeasure.NA}`;
    } else {
      return `${range} ${this.showTUOMInHeader() ? '' : unitOfMeasureString}`.trim();
    }
  }

  protected getTerpeneAsString(terpenePascalCased: string, units: string, fixedPoint?: number): string {
    const terpeneCamelCased = StringUtils.pascalCaseToCamelCase(terpenePascalCased);
    const parsedTerpene = this.getMinRowCannabinoidOrTerpene(terpeneCamelCased);
    if (parsedTerpene < 1) {
      return (this.showTUOMInHeader() ? `<1` : `<1 ${units}`).trim();
    } else {
      if (fixedPoint) {
        return `${parsedTerpene.toFixed(fixedPoint)} ${this.showTUOMInHeader() ? '' : units}`.trim();
      } else {
        const roundedTerpene = `${(Math.round((parsedTerpene + Number.EPSILON) * 100) / 100)} `
          + `${this.showTUOMInHeader() ? '' : units}`;
        return roundedTerpene.trim();
      }
    }
  }

  /**
   * @deprecated use getCannabinoid instead
   */
  public getThc(): string {
    const variants = this.getScopedVisibleVariantsElseRowVariants();
    if (variants.length > 0) {
      const shouldDisplayTHC = variants?.firstOrNull()?.shouldDisplayCannabinoidValue();
      if (shouldDisplayTHC) {
        if (variants?.firstOrNull().cannabisUnitOfMeasure === CannabisUnitOfMeasure.NA) {
          return '--';
        }
        const unitOfMeasureString = variants?.firstOrNull()?.getCannabinoidUnitOfMeasureString(this.menu);
        // Get THC value
        if (this.displayCannabinoidInRanges()) {
          return this.getThcRowRangeAsString(unitOfMeasureString);
        } else {
          return this.getThcAsString(unitOfMeasureString);
        }
      } else {
        return '-';
      }
    }
    return '';
  }

  /**
   * @deprecated use getCannabinoidRowRangeAsString instead
   */
  protected getThcRowRangeAsString(unitOfMeasureString: string, fixedPoint?: number): string {
    const thcRange = this.getRowRangeCannabinoidOrTerpene('THC', fixedPoint);
    if (thcRange === '') {
      return `${CannabisUnitOfMeasure.NA}`;
    } else {
      return `${thcRange} ${this.showCUOMInHeader() ? '' : unitOfMeasureString}`.trim();
    }
  }

  /**
   * @deprecated use getCannabinoidAsString instead
   */
  protected getThcAsString(units: string, fixedPoint?: number): string {
    const parsedThc = this.getMinRowCannabinoidOrTerpene('THC');
    if (parsedThc < 1) {
      return (this.showCUOMInHeader() ? `<1` : `<1 ${units}`).trim();
    } else {
      if (fixedPoint) {
        return `${parsedThc.toFixed(fixedPoint)} ${this.showCUOMInHeader() ? '' : units}`.trim();
      } else {
        const roundedThc = `${(Math.round((parsedThc + Number.EPSILON) * 100) / 100)} `
          + `${this.showCUOMInHeader() ? '' : units}`;
        return roundedThc.trim();
      }
    }
  }

  /**
   * @deprecated use getCannabinoid instead
   */
  public getCbd(): string {
    const variants = this.getScopedVisibleVariantsElseRowVariants();
    if (variants.length > 0) {
      const shouldDisplayCBD = variants?.firstOrNull()?.shouldDisplayCannabinoidValue();
      if (shouldDisplayCBD) {
        if (variants?.firstOrNull().cannabisUnitOfMeasure === CannabisUnitOfMeasure.NA) {
          return '--';
        }
        const unitOfMeasureString = variants?.firstOrNull()?.getCannabinoidUnitOfMeasureString(this.menu);
        // Get CBD Value
        if (this.displayCannabinoidInRanges()) {
          return this.getCbdRowRangeAsString(unitOfMeasureString);
        } else {
          return this.getCbdAsString(unitOfMeasureString);
        }
      } else {
        return '-';
      }
    }
    return '';
  }

  /**
   * @deprecated use getCannabinoidRowRangeAsString instead
   */
  protected getCbdRowRangeAsString(unitOfMeasureString: string, fixedPoint?: number): string {
    const cbdRange = this.getRowRangeCannabinoidOrTerpene('CBD', fixedPoint);
    if (cbdRange === '') {
      return `${CannabisUnitOfMeasure.NA}`;
    } else {
      return `${cbdRange} ${this.showCUOMInHeader() ? '' : unitOfMeasureString}`.trim();
    }
  }

  /**
   * @deprecated use getCannabinoidAsString instead
   */
  protected getCbdAsString(units: string, fixedPoint?: number): string {
    const parsedCbd = this.getMinRowCannabinoidOrTerpene('CBD');
    if (parsedCbd < 1) {
      return (this.showCUOMInHeader() ? `<1` : `<1 ${units}`).trim();
    } else {
      if (fixedPoint) {
        return `${parsedCbd.toFixed(fixedPoint)} ${this.showCUOMInHeader() ? '' : units}`.trim();
      } else {
        const roundedCbd = `${(Math.round((parsedCbd + Number.EPSILON) * 100) / 100)} `
          + `${this.showCUOMInHeader() ? '' : units}`;
        return roundedCbd.trim();
      }
    }
  }

  public getCUOM(): string {
    return this.rowVariants?.firstOrNull()?.getCannabinoidUnitOfMeasureString(this.menu);
  }

  public getRowUniqueCUOM(): CannabisUnitOfMeasure {
    return this.rowVariants?.map(v => v.cannabisUnitOfMeasure)?.uniqueInstance(true) ?? CannabisUnitOfMeasure.UNKNOWN;
  }

  public getRowUniqueTUOM(): TerpeneUnitOfMeasure {
    return this.rowVariants?.map(v => v.terpeneUnitOfMeasure)?.uniqueInstance(true) ?? TerpeneUnitOfMeasure.UNKNOWN;
  }

  public getSectionUniqueCUOM(): CannabisUnitOfMeasure {
    const sectionCUOMs = this.section
      ?.getScopedVisibleVariants(this.menu, this.locationConfig?.priceFormat, this.menu?.isSectionLevelOverflow())
      ?.map(v => v.cannabisUnitOfMeasure);
    return sectionCUOMs.uniqueInstance(true) ?? CannabisUnitOfMeasure.UNKNOWN;
  }

  public getSectionUniqueTUOM(): TerpeneUnitOfMeasure {
    const sectionTUOMs = this.section
      ?.getScopedVisibleVariants(this.menu, this.locationConfig?.priceFormat, this.menu?.isSectionLevelOverflow())
      ?.map(v => v.terpeneUnitOfMeasure);
    return sectionTUOMs.uniqueInstance(true) ?? TerpeneUnitOfMeasure.UNKNOWN;
  }

  displayCannabinoidInRanges(): boolean {
    const companyUsesCannabinoidRange = this.companyConfig?.cannabinoidDisplayType === CannabinoidDisplayType.Range;
    const someRowVariantsUseCannabinoidRange = () => {
      return this.getScopedVisibleVariantsElseRowVariants()?.some(v => v.useCannabinoidRange);
    };
    return companyUsesCannabinoidRange || someRowVariantsUseCannabinoidRange();
  }

  displayTerpeneInRanges(): boolean {
    const companyUsesTerpeneRange = this.companyConfig?.terpeneDisplayType === TerpeneDisplayType.Range;
    const someRowVariantsUseTerpeneRange = () => {
      return this.getScopedVisibleVariantsElseRowVariants()?.some(v => v?.useTerpeneRange);
    };
    return companyUsesTerpeneRange || someRowVariantsUseTerpeneRange();
  }

  public showCUOMInHeader(): boolean {
    const uniqueCUOM = this.getSectionUniqueCUOM();
    return this.menu?.menuOptions?.showCUOMInHeader && !!uniqueCUOM;
  }

  public showTUOMInHeader(): boolean {
    const uniqueTUOM = this.getSectionUniqueTUOM();
    // showTUOMInHeader doesn't exist, use showCUOMInHeader instead
    return this.menu?.menuOptions?.showCUOMInHeader && !!uniqueTUOM;
  }

  public getQuantityInStock(): number {
    return this.rowVariants?.firstOrNull()?.inventory?.quantityInStock ?? 0;
  }

  public getQuantityAndSizeString(): string {
    const productType = this.rowProductType();
    const isEdible = productType === ProductType.Edible;
    const isSeed = productType === ProductType.Seed;
    const isReadyToDrinkBeverage = VariantTypeUtils.isReadyToDrinkBeverageType(this.rowVariantType());
    switch (true) {
      case (isEdible && !isReadyToDrinkBeverage):
        return this.getQuantityAndSizeHelperForEdiblesThatAreNotReadyToDrinkBeverages();
      case isSeed:
        return this.getQuantityAndSizeHelperForSeeds();
      default:
        const quantity = this.getQuantityString();
        const size = this.getSize();
        return (!size || size === '-' || size === '--')
          ? size
          : [quantity, size].filter(s => !!s).join(' x ');
    }
  }

  protected getQuantityAndSizeHelperForSeeds(): string | null {
    const packageQuantity = this.getMinPackageQuantity();
    return `${packageQuantity} ${this.seedPackageQuantityWord()}`;
  }

  protected getQuantityAndSizeHelperForEdiblesThatAreNotReadyToDrinkBeverages(): string | null {
    const packageQuantity = this.getMinPackageQuantity();
    return `${packageQuantity} ${this.edibleNonReadyToDrinkPackageQuantityWord()}`;
  }

  protected edibleNonReadyToDrinkPackageQuantityWord(variant?: Variant): string {
    return 'Pack';
  }

  protected seedPackageQuantityWord(variant?: Variant): string {
    return 'Pack';
  }

  public getQuantityString(variant?: Variant): string {
    return this.getQuantity(variant).toString();
  }

  public getQuantity(variant?: Variant): number {
    if (exists(variant)) {
      return variant?.packagedQuantity;
    } else if (this.variantLineItemMode) {
      return this.rowVariants?.firstOrNull()?.packagedQuantity;
    } else {
      return 1;
    }
  }

  public getGridQuantity(variant?: Variant) {
    if (exists(variant)) {
      return variant?.packagedQuantity;
    } else if (this.variantLineItemMode) {
      return this.rowVariants?.firstOrNull()?.packagedQuantity;
    } else {
      return 1;
    }
  }

  public getMinPackageQuantity(): number {
    const packageQuantity = this.rowVariants?.map(v => v?.packagedQuantity) || [];
    const min = Math.min(...packageQuantity);
    return Number.isFinite(min) ? min : 0;
  }

  public getMaxPackageQuantity(): number {
    const packageQuantity = this.rowVariants?.map(v => v?.packagedQuantity) || [];
    return Math.max(...packageQuantity, 0);
  }

  /**
   * Returns the minimum unit size of the variants in the group.
   * Can return Infinity if unit sizes are not specified.
   */
  public getMinNumericUnitSize(): number {
    const size = this.rowVariants?.map(v => v?.unitSize) || [];
    return Math.min(...size);
  }

  public getMaxNumericUnitSize(): number {
    const size = this.rowVariants?.map(v => v?.unitSize) || [];
    return Math.max(...size, 0);
  }

  public getSize(variant?: Variant): string {
    if (exists(variant)) {
      return variant?.getFormattedUnitSize();
    } else if (this.variantLineItemMode) {
      return this.rowVariants?.firstOrNull()?.getFormattedUnitSize();
    } else {
      return '';
    }
  }

  public hasBadges(): boolean {
    return this.getAllVariantBadges()?.length > 0;
  }

  public hasBrands(): boolean {
    return this.rowVariants?.firstOrNull()?.brand?.length > 0;
  }

  public getAllVariantBadges(): VariantBadge[] {
    return BadgeUtils.getVariantVisibleBadges(this.menu, this.section, this.getScopedVisibleVariants());
  }

  rowStrainType(): StrainType {
    return this.rowVariants?.find(v => exists(v?.classification))?.classification;
  }

  rowProductType(): ProductType | null {
    return this.rowVariants?.find(v => exists(v?.productType))?.productType;
  }

  rowVariantType(): VariantType | null {
    return this.rowVariants?.find(v => exists(v?.variantType))?.variantType;
  }

  getHideSale(): boolean {
    return this.menu?.menuOptions?.hideSale;
  }

  hideOverflowItem(): boolean {
    return this.itemOverflowStatus === SectionItemOverflowStatus.Overflow;
  }

  isAccessory(): boolean {
    return this.rowVariants?.some(variant => variant?.productType === ProductType.Accessories);
  }

  public isNonCannabinoidOtherVariant(): boolean {
    return this.rowProductType() === ProductType.Other
      && !VariantTypeUtils.isOtherTypeWithCannabinoids(this.rowVariantType());
  }

  public isNonCannabinoidVariant(): boolean {
    return this.isNonCannabinoidOtherVariant() || this.isAccessory();
  }

  public inStockRowVariants(): Variant[] {
    return this.variantLineItemMode ? this.rowVariants : this.rowVariants?.filter(v => v.inventory.inStock());
  }

  public getVariantMatch(columnVM: SectionColumnViewModel): Variant {
    return this.getVariantFromGridColumn(columnVM);
  }

  public showFullDescription(): boolean {
    return this.menu?.menuOptions?.showFullDescription ?? false;
  }

  getProductDesc(): string {
    if (this.menu?.menuOptions?.hideDescription) {
      return null;
    } else if (this.menu?.menuOptions?.showFullDescription) {
      return this.getScopedVisibleVariants()?.map(v => v?.description)?.filterNulls()?.firstOrNull();
    } else {
      return this.getScopedVisibleVariants()?.map(v => v?.shortDescription)?.filterNulls()?.firstOrNull()
          || this.getScopedVisibleVariants()?.map(v => v?.description)?.filterNulls()?.firstOrNull();
    }
  }

  getRichTextDescription(): string {
    if (this.menu?.menuOptions?.hideDescription || !this.menu?.menuOptions?.showFullDescription) {
      // If the description is hidden, or not set to use the full description, return null
      return null;
    } else {
      return this.getScopedVisibleVariants()?.map(v => v?.richTextDescription)?.filterNulls()?.firstOrNull();
    }
  }

  /**
   * Is the variant scoped to the currently selected grid columns and is the variant price visible and greater than 0?
   * Then the variant is scoped and visible, so add it to the list of returned variants.
   */
  getScopedVisibleVariants(): Variant[] {
    if (this.variantLineItemMode || this.section?.isChildVariantListMode()) {
      return this.rowVariants;
    } else {
      const themeId = this.menu?.theme;
      const locationId = this.menu?.locationId;
      const companyId = this.menu?.companyId;
      const priceStream = this.locationConfig?.priceFormat;
      const hideSale = this.getHideSale();
      return this.rowVariants?.filter(variant => {
        const isWithinGridScope = this.section?.isVariantWithinGridScope(variant, this.section?.layoutType, locationId);
        const hidePrice = !!this?.hidePriceOnVariantIds?.find(id => id === variant?.id);
        let price: number;
        // always show out of stock products for print card menus
        const showZeroStockItems = MenuUtils.isPrintCardMenu(this.menu) || this.section?.showZeroStockItems;
        if (showZeroStockItems || variant?.inventory?.inStock()) {
          price = variant?.getVisiblePrice(themeId, locationId, companyId, priceStream, hideSale);
        }
        return (!isWithinGridScope || hidePrice) ? false : price > 0;
      });
    }
  }

  /**
   * Are there any scoped & visible variants? Then return them, else return this.rowVariants.
   */
  getScopedVisibleVariantsElseRowVariants(): Variant[] {
    const visibleAndHavePrice = this.getScopedVisibleVariants();
    return visibleAndHavePrice?.length > 0 ? visibleAndHavePrice : this.rowVariants;
  }

  getLayoutType(): SectionLayoutType {
    return this.section?.layoutType;
  }

  incrementUniqueIdOffset(): void {
    this.uniqueIdOffset++;
  }

  uniqueId(): string {
    return this.rowVariants?.map(v => v.id)?.sort()?.join(',');
  }

  /**
   * This is needed for section level snap transitions.
   *
   * Problem: when rowCount === rowViewModels?.length + 1, trackBy fails and destroys all line items added to the DOM,
   * and then recreates them, which causes the snap transition to fail.
   *
   * Solution: to combat this, we add a unique id offset to the uniqueId, which we call uniqueIdWithTransitionOffset.
   * This offset increments by 1 right before the rowViewModel is about to be snapped into view.
   * This stops the trackBy decorator from destroying/recreating the line items added to the DOM.
   */
  uniqueIdWithTransitionOffset(): string {
    return this.uniqueId() + this.uniqueIdOffset;
  }

  getVariantFromGridColumn(columnVM: SectionColumnViewModel): Variant {
    return this.rowVariants?.find(v => {
      const layoutType = columnVM?.sectionLayoutType;
      if (!this.section?.enabledVariantIds?.find(i => i === v?.id)) {
        return false;
      } else if (layoutType === SectionLayoutType.List || layoutType === SectionLayoutType.ChildVariantList) {
        return true;
      } else {
        return v?.getGridNames(layoutType, this.locationConfig?.locationId)?.contains(columnVM.columnTitle);
      }
    });
  }

  /* ************************** Label Component Interface ************************** */

  getMenuForLabelComponent(): Menu {
    return this.menu;
  }

  getSectionForLabelComponent(): SectionWithProducts {
    return this.section;
  }

  getVariantsForLabelComponent(): Variant[] {
    return this.getScopedVisibleVariants();
  }

  getLocationConfigForLabelComponent(): LocationConfiguration {
    return this.locationConfig;
  }

  getCompanyConfigForLabelComponent(): CompanyConfiguration {
    return this.companyConfig;
  }

  getShutOffLabelForLabelComponent(): boolean {
    return !this.menu?.getShowInlineLabels();
  }

  getOverridePriceStreamForLabelComponent(): LocationPriceStream {
    return null;
  }

  /* ****************************************************************************** */

}
